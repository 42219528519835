import { history } from 'umi';
import { useFormatMsg, locale } from '@/utils/locale';
import crypto from 'crypto';
import NodeRSA from 'node-rsa';
import { extend, ResponseError, RequestMethod } from 'umi-request';
import { v4 } from 'uuid';
import appInfo from '@/stores/appInfo';
import common from '@/utils/v2/common';
import { Toast as CreditToast } from '@credit/tips';
import config from '@/config';
import UserInfo from '@/stores/userInfo/api.interface';
import { MDAP, ICustomMessage } from '@/utils/mdap';
import { MDAPApiErrorReport } from '@/utils/businessErrorReport';
import insight from '@/utils/v2/insight';
import { ExceptionCode } from '@/types/constant';
import { getRiskInfo } from '@shopee/risk-info-sdk';

const formatMessage = useFormatMsg();

export interface IRequestParams {
  method: 'POST' | 'PUT' | 'DELETE' | 'GET';
  url: string;
  body?: Record<string, any>;
  bodyHeader?: Record<string, any>;
  headers?: Record<string, any>;
  options?: Record<string, any>;
  manual?: boolean;
}

export class Request {
  private umiRequest: RequestMethod;

  private baseHeaders = {
    'X-Kredit-Req-APPID': config.appId,
    'X-Kredit-Req-Source': '1',
    'X-Kredit-Req-Language': locale,
    Authorization: config.Authorization,
  };

  private secretKey = config.secretKey; // rsa私钥
  private publicKey = config.publicKey; // rsa公钥

  private version = 1; // 版本， 默认为1
  private platForm = 1; // 平台，1: shopee
  private region: string = BIZ_COUNTRY; // 国家或地区
  private appVersion: number; // app版本号
  private rnVersion: number; // rn版本号
  private shopeeRnBundleVersion: number; // shopee_rn_bundle_version的版本号

  constructor() {
    this.appVersion = common.getAppVersion();
    this.rnVersion = common.getRnVersion();
    this.shopeeRnBundleVersion = common.getShopeeRnBundleVersion();

    this.umiRequest = extend({
      prefix: '/api',
      errorHandler: this.defaultErrorHandler, // 默认错误处理
      credentials: 'include', // 默认请求是否带上cookie
      useCache: false,
      timeout: 10000, // 请求失效的时间
      validateCache: () => false,
      requestType: 'json',
      responseType: 'json',
      getResponse: true,
      parseResponse: true,
      throwErrIfParseFail: true,
    });

    this.umiRequest.interceptors.request.use(
      (url, options) => {
        return {
          url,
          options,
        };
      },
      {
        global: false,
      },
    );

    this.umiRequest.interceptors.response.use(
      async (response, options) => {
        const status = response?.status ?? 0;
        // 处理503维护页逻辑
        if (parseInt(`${status}`, 10) === ExceptionCode.MaintenanceException) {
          let resBody: Record<string, any> = {};
          try {
            resBody = (await response?.clone()?.json()) || {};
          } catch (error) {
            resBody = {};
          }
          console.log('response.use resBodyresBody', resBody);
          const start = resBody?.result?.start_time || '';
          const end = resBody?.result?.end_time || '';
          const transfer = resBody?.result?.transfer_key || '';
          history.replace(`/maintenance?start=${start}&end=${end}&transfer=${transfer}`);
          const err = { response };
          return Promise.reject(err);
        }
        if (!response || parseInt(`${status}`, 10) >= 400) {
          const err = { response };
          return Promise.reject(err);
        }
        let data: any = await response.clone().json();
        data = this.mixCode(data);
        this.checkSpecCode(data, options);
        console.log('response.use', data);
        return data;
      },
      {
        global: false,
      },
    );
  }

  /**
   * 将UC返回的错误信息提到最外层
   * @param response
   * @returns
   */
  protected mixCode = (response: any) => {
    switch (true) {
      case !!response?.code:
        if (response?.result?.common_result?.err_code) {
          response.result.common_result.code = response.code;
        }
        break;
      case !!response?.result?.common_result?.err_code:
        response.result.common_result.code = response.code;
        response.code = response.result.common_result.err_code;
        break;
      default:
        break;
    }
    return response;
  };

  /**
   * 统一处理
   * @param response
   * @param options
   */
  protected checkSpecCode = (response: any, options: any) => {
    const { headers = {} as any, url } = options;
    const code = response?.code;
    switch (true) {
      case code === 90006 && url.indexOf(UserInfo.API.get_user_info) >= 0:
        // 为校验是否已登录放款此接口登录校验。
        // 因为在登录并获取用户信息时有判断code并直接跳转/exception页面，故不会进入接口调用死循环。
        break;
      case code >= 90001 && code <= 90016:
        history.replace('/exception');
        break;
      case code !== 0 && !options?.manual:
        CreditToast.fail(response?.msg ?? formatMessage({ id: 'network_error' }));
        break;
      default:
        break;
    }

    const traceId = headers['X-Kredit-Trace-ID'];
    if (code === 0) {
      MDAP.apiInfoReport({
        traceId,
        apiPath: url,
      });
    } else {
      MDAPApiErrorReport({
        traceId,
        apiPath: url,
        response,
        options,
        user: common.getAppState('userInfo.userInfo') ?? {},
      });
      insight?.apiReport({
        url,
        resp_code: `${response?.code}`,
        message: `${response?.msg}` ?? 'code is not 0',
      });
    }
    if (response?.code === 0 && response?.msg?.toLowerCase() !== 'success') {
      MDAPApiErrorReport(
        {
          traceId,
          apiPath: url,
          response,
          options,
          user: common.getAppState('userInfo.userInfo') ?? {},
        },
        ICustomMessage.API_MESSAGE_ERROR,
      );
      insight?.apiReport({
        url,
        resp_code: `${response?.code}`,
        message: response?.msg || 'unknown msg',
      });
    }
  };

  /**
   * 对请求参数进行签名
   * @param data
   * @param encode
   * @returns string
   */
  protected signature(data: Record<string, any>, encode = 'base64') {
    try {
      const str = JSON.stringify(data) + this.secretKey;
      const hash = crypto.createHash('sha256');
      hash.update(str);
      const hashString = hash.digest('hex');
      const key = new NodeRSA(this.publicKey, 'pkcs8-public', {
        encryptionScheme: 'pkcs1_oaep',
        signingScheme: 'pkcs1-sha1',
      });

      const signature = key.encrypt(hashString, encode as NodeRSA.Encoding);
      return signature;
    } catch (error) {
      throw new Error('Sigature Error');
    }
  }

  /**
   * 设置头部信息
   * @param signature
   * @param headers
   * @returns
   */
  protected setHeaders(signature: string, headers: Record<string, any>) {
    return {
      ...this.baseHeaders,
      'X-Kredit-Req-H': signature,
      'X-Kredit-Trace-ID': v4(),
      'X-Kredit-Start-Timestamp': `${Date.now()}`,
      'X-Kredit-System-Type': common.getDeviceSystem(),
      'X-Kredit-Rn-Version': `${this.rnVersion}`,
      'X-Kredit-Rn-Bundle-Version': `${this.shopeeRnBundleVersion}`,
      ...headers,
    };
  }

  /**
   * 请求错误的处理
   * @param e ResponseError
   * @returns void
   */
  private defaultErrorHandler(e: ResponseError) {
    const { response = {} as Response } = e || {};
    const headers = (e?.request?.options?.headers as Record<string, string>) ?? {};
    const traceId = headers?.['X-Kredit-Trace-ID'] ?? '';
    const url = e?.request?.url ?? '';

    MDAPApiErrorReport(
      {
        traceId,
        apiPath: url,
        options: e?.request || {},
        response: e?.response || {},
        user: common.getAppState('userInfo.userInfo') ?? {},
      },
      ICustomMessage.API_UNCAUGHT_ERROR,
    );

    insight?.apiReport({
      url,
      resp_code: `-1`,
      message: 'api uncaught error',
    });

    return {
      // 避免response的status等于0的情况
      code: response?.status === 0 ? -1 : response?.status,
      traceId,
      msg: formatMessage({ id: 'network_error' }),
      errDeal: true,
      result: {},
    };
  }

  /**
   * 获取body header
   * @param neekRisk boolean
   * @returns Record<string, any>
   */
  private async getBodyHeader(neekRisk: boolean = false) {
    // 拿appInfo的信息
    const {
      deviceID,
      deviceFingerprint,
      from,
      model,
      brand,
      networkType,
      cellularType,
      deviceFingerprintToken,
    } = appInfo;
    const commonData = {
      version: this.version,
      platform_type: this.platForm, // 默认传1，1表示shopee
      system_version: common.getSystemVersion(),
      lang: locale,
      from,
      app_version: `${this.appVersion}`,
      rn_version: `${this.rnVersion}`,
      shopee_rn_bundle_version: `${this.shopeeRnBundleVersion}`,
      region: this.region,
      system_type: common.getDeviceSystem(),
      device_id: deviceID,
      device_fingerprint: deviceFingerprint,
      device_fingerprint_token: deviceFingerprintToken,
    };
    // 重构后的新系统前端不直接调risk接口查询风控信息，而是去core查询，所以新系统不用传risk_device_info
    // let riskDeviceInfo;
    // try {
    //   // 第一次调用可能出现等待，后面调用直接返回结果
    //   const { data } = await getRiskInfo({
    //     metrics: {
    //       location: appInfo.gpsloc,
    //     },
    //   });
    //   riskDeviceInfo = data;
    // } catch (e) {
    //   riskDeviceInfo = '';
    // }

    let riskHeader: any = {};
    if (neekRisk) {
      riskHeader = {
        device_model: model,
        device_brand: brand,
        network_type: networkType,
        cellular_type: cellularType,
      };
    }
    return {
      ...commonData,
      ...riskHeader,
      // risk_device_info: riskDeviceInfo,
    };
  }

  /**
   * request
   * @param method 'POST' | 'PUT' | 'DELETE' | 'GET' = 'POST'
   * @param url {string} 请求url
   * @param data {Record<string, any>} 参数
   * @param toast {boolean} 是否默认弹错误提示
   * @param bodyHeader {Record<string, any>} 透传给网关的参数
   * @param headers {Record<string, any>} 请求头部的参数
   * @param options {Record<string, any>} umi-request options
   * @returns Promise
   */
  protected async request({
    method = 'POST',
    url,
    body = {},
    bodyHeader = {},
    headers = {},
    options = {},
    manual = false,
  }: IRequestParams) {
    try {
      const commonBodyHeader = await this.getBodyHeader();
      const requestData = {
        header: {
          ...commonBodyHeader,
          ...bodyHeader,
        },
        message: {
          biz_common: common.BizCommon,
          ...body,
        },
      };

      // 参数签名
      const signature = this.signature(requestData);

      // 重组Reqeust Header
      const umiHeaders = this.setHeaders(signature, headers);

      const resp = await this.umiRequest(url, {
        method,
        headers: umiHeaders,
        data: requestData,
        manual,
        ...options,
      });

      insight?.apiReport({
        url,
        resp_code: `${resp?.code}`,
        message: resp?.msg || 'unknown',
      });

      return resp;
    } catch (e) {
      MDAPApiErrorReport(
        {
          traceId: '',
          apiPath: url,
          options,
          error: e,
        },
        ICustomMessage.API_UNCAUGHT_ERROR,
      );
      insight?.apiReport({
        url,
        resp_code: `-1`,
        message: 'request error',
      });
      return {
        code: -1,
        msg: 'request error',
        result: {},
      };
    }
  }

  /**
   * POST 请求
   * @param url {string} 请求url
   * @param body {Record<string, any>} 参数
   * @param manual {boolean} 是否默认弹错误提示
   * @param bodyHeader {Record<string, any>} 透传给网关的参数
   * @param headers {Record<string, any>} 请求头部的参数
   * @param options {Record<string, any>} umi-request options
   * @returns Promise
   */
  async post<T = any>(
    url: string,
    params: Record<string, any> = {},
  ): Promise<{ code: number; result: T; msg: string }> {
    const pathname = window?.location?.pathname;

    // 更新risk的DeviceFingerprintToken
    const { needCheckDeviceFingerprintToken, token: deviceFingerprintToken } =
      await appInfo.setDeviceFingerprintToken(false, url);

    // 组装 bodyHeader
    const bodyHeader = {
      location: appInfo?.gpsloc,
      ...(params?.bodyHeader ?? {}),
      device_fingerprint_token: deviceFingerprintToken,
    };

    const options = {
      method: 'POST',
      url,
      ...params,
      bodyHeader,
    };

    const resp = await this.request(options as any);

    // @ts-ignore
    window?.__track_sdk?.setCache(pathname, url, resp);

    if (needCheckDeviceFingerprintToken && !deviceFingerprintToken) {
      const traceId = resp?.request_id ?? '';
      MDAPApiErrorReport(
        {
          traceId,
          apiPath: url,
          user: common.getAppState('userInfo.userInfo') ?? {},
          deviceFingerprintToken,
          options,
        },
        ICustomMessage.DEVICE_FINGERPRINT_TOKEN_ERROR,
      );
      insight?.apiReport({
        url,
        resp_code: `-1`,
        message: 'device fingerprint token error',
      });
    }

    return resp;
  }
}

export default new Request();
