// 主动还款order类型
export enum REPAYABLE_TYPE {
  NONE = 0,
  DOB = 1, // ID独有
  OVERDUE = 2,
  PARTIALREPAID = 3, // 结算还款，ID以外国家均采用这个类型
}

export enum REPAY_CHANNEL_ID {
  SELLER_BALANCE = '8001300',
  SHOPEE_PAY = '8001400',
  AIRPAY_WALLET = '7001800',
  AIRPAY_GIRO = '7002900',
  FAST_BALANCE = '8003600',
}

export enum BR_REPAY_CHANNEL_ID {
  SHOPEE_PAY = '10001400',
  SPINPAY_PIX = '10014600', // Spinpay后续将被spm替换成Santander_PIX
  Santander_PIX = '10014900',
}

export enum TH_REPAY_CHANNEL_ID {
  SHOPEE_PAY = '7001800',
  SHOPEEPAY_LINK_BANK_ACCOUNT = '7002900',
  QR_PROMOTPAY = '7016500',
  MOBILE_BANKING = '7006600',
}

export enum MY_REPAY_CHANNEL_ID {
  SHOPEE_PAY = '2001400',
  IPAY88 = '2002700',
}

// 还款单状态
export enum REPAY_STATUS {
  NONE = 0,
  UNPAID = 1,
  CANCELLED = 2,
  PAID = 3,
  FAILED = 4,
  DELAY_PAID = 5,
}

export enum REFACTOR_REPAY_STATUS {
  NONE = 0,
  UNPAID = 1,
  PENDING = 2, // delete, not used
  PAID = 3,
  CANCELLED = 4,
  FAILED = 5,
  DELAY_PAID = 6,
  DEDUCTING = 7,
  DEDUCTED = 8,
  COMPLETE = 9,
}

export enum RepayStatusEnum {
  FAIL = 'rejected',
  PENDING = 'pending',
  SUCCESS = 'success',
}

export const StatusToType: { [key: number]: RepayStatusEnum } = {
  [REFACTOR_REPAY_STATUS.NONE]: RepayStatusEnum.PENDING,
  [REFACTOR_REPAY_STATUS.UNPAID]: RepayStatusEnum.PENDING,
  [REFACTOR_REPAY_STATUS.PENDING]: RepayStatusEnum.PENDING,
  [REFACTOR_REPAY_STATUS.PAID]: RepayStatusEnum.PENDING,
  [REFACTOR_REPAY_STATUS.CANCELLED]: RepayStatusEnum.FAIL,
  [REFACTOR_REPAY_STATUS.FAILED]: RepayStatusEnum.FAIL,
  [REFACTOR_REPAY_STATUS.DELAY_PAID]: RepayStatusEnum.PENDING,
  [REFACTOR_REPAY_STATUS.DEDUCTING]: RepayStatusEnum.PENDING,
  [REFACTOR_REPAY_STATUS.DEDUCTED]: RepayStatusEnum.SUCCESS,
  [REFACTOR_REPAY_STATUS.COMPLETE]: RepayStatusEnum.SUCCESS,
};

export interface IOrder {
  order_sn: string;
  overdue_time: number;
  outstanding_amount: number;
  repayment_plan_id: number;
}

export interface IRepayOrder {
  repayable_type: REPAYABLE_TYPE; // 订单种类： 2-overdue  1-DOB10+
  total_amount: number; // 可还款总金额
  order_list: IOrder[];
}

export interface IRepayment {
  total_amount: number;
  overflow_amount: number;
  overdueInfo: IRepayOrder;
  repayableInfo: IRepayOrder;
  selectedChannelName: string;
  selectedChannelId: number;
  payOption: string;
  loading: boolean;
}

type CodeHandler = () => void;

export interface CodeHandlers {
  [code: number]: CodeHandler;
  default: CodeHandler;
}
