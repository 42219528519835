import bridge from '@/utils/bridge';
import { queryParams, isAndroid, isIOS, getAppVersion } from '@credit/utils';
// import * as Sentry from '@sentry/browser';

enum APP_VERSION {
  getDeviceFingerprint = 26900,
  getRiskToken = 27000,
}

class AppInfo {
  public from: string;
  public authCode: string;
  public nonceCode: string;
  public deviceID: string;
  public deviceFingerprint: string;
  public deviceFingerprintToken: string;
  public needCheckDeviceFingerprintToken: boolean;
  public brand: string | number;
  public model: string | number;
  public networkType: string | number;
  public cellularType: string | number;
  public pageIdMap: { [prop: string]: string };
  public loadingList: string[] = [];
  public firstPathname: string = '';
  public gpsloc: any;

  constructor() {
    this.from = '';
    this.authCode = '';
    this.nonceCode = '';
    this.deviceID = '';
    this.deviceFingerprint = '';
    this.deviceFingerprintToken = '';
    this.needCheckDeviceFingerprintToken = false;
    this.brand = '';
    this.model = '';
    this.networkType = 0;
    this.cellularType = '';
    this.pageIdMap = {};
    this.gpsloc = undefined;
  }

  public async setDeviceFingerprintToken(flag?: boolean, subUrl?: string) {
    // 若是Insight上报请求则不重新获取deviceFingerprintToken。
    if (flag) {
      return {
        needCheckDeviceFingerprintToken: this.needCheckDeviceFingerprintToken,
        token: this.deviceFingerprintToken,
        resp: null,
      };
    }

    let token: string = '';
    let resp: any = null;
    let needCheckDeviceFingerprintToken: boolean = false;

    try {
      const appVersion = getAppVersion();

      if (isIOS() && appVersion >= APP_VERSION.getRiskToken) {
        needCheckDeviceFingerprintToken = true;
        resp = await bridge.call('getRiskToken' as any);
        token = resp?.data?.riskToken;
      }

      if (isAndroid() && appVersion >= APP_VERSION.getDeviceFingerprint) {
        needCheckDeviceFingerprintToken = true;
        resp = await bridge.call('getDeviceFingerprint' as any);
        token = resp?.data?.fingerprint;
      }

      this.deviceFingerprintToken = token;
      this.needCheckDeviceFingerprintToken = needCheckDeviceFingerprintToken;
      return {
        needCheckDeviceFingerprintToken,
        token,
        resp,
      };
    } catch (error) {
      // Sentry.captureMessage('DEVICE_FINGERPRINT_TOKEN_ERROR', {
      //   extra: {
      //     url: subUrl,
      //     error,
      //     resp,
      //   },
      // });

      return {
        needCheckDeviceFingerprintToken: this.needCheckDeviceFingerprintToken,
        token: this.deviceFingerprintToken,
        resp,
      };
    }
  }

  // 设置设备信息
  public async setDeviceInfo() {
    const res: any = await bridge.getAppInfo();
    const { deviceID, deviceFingerprint } = res || {};
    this.deviceID = deviceID || '';
    this.deviceFingerprint = deviceFingerprint || '';
    this.firstPathname = window.location.pathname;
  }

  public async setExtraInfo() {
    const [res1, res2]: any[] = await Promise.all([
      bridge.getDeviceInfo(),
      bridge.getNetworkInfo(),
    ]);
    const { brand, model } = res1 || {};
    const { networkType, cellularType } = res2 || {};

    this.brand = brand || '';
    this.model = model || '';
    this.networkType = networkType || 0;
    this.cellularType = cellularType || '';

    const [hasLocPermission] = await bridge.checkPermission(['location']);
    if (hasLocPermission) {
      const gpsloc = await bridge.getLocation(true);
      this.gpsloc = gpsloc;
      // console.log('gpsloc init: ', gpsloc, '; at ', new Date().toString());
    }
  }

  // 初始化时设置公共model
  public setAppInfo() {
    const { from = '' } = queryParams();

    this.from = from;
    this.setDeviceInfo();
    // this.setDeviceFingerprintToken();
  }
}

export default new AppInfo();
