import Bridge from '@credit/ibridge';
import { MDAP } from '@/utils/mdap';

class BridgeWithTrace extends Bridge {
  public callWebview(url: string, title?: any, popSelf?: 1 | 0): void {
    MDAP.bridgeCallReport({
      url,
      title,
      popSelf,
    });
    return super.callWebview(url, title, popSelf);
  }

  public callNavigateAppRL(apprl: string, params: any = {}) {
    MDAP.bridgeCallReport({
      apprl,
      params,
    });
    return super.callNavigateAppRL(apprl, params);
  }
}

const bridge = new BridgeWithTrace();

bridge.setConfig({
  defaultTitle: APP_TITLE,
  stopTrack: BIZ_COUNTRY === 'th',
});

export default bridge;
